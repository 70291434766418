import {Fragment, lazy} from "react";
import {useDispatch, useSelector} from "react-redux";
import {modalActions} from "../../../store/modal";

import classes          from './Modal.module.scss';
import Login            from '../../Login/Login'
import Registration     from "../../Registration/Registration";
import ForgotPassword   from "../../ForgotPassword/ForgotPassword";
import Preferences      from "../../Preferences/Preferences";
import Membership       from "../../Membership/Membership";
import MembershipFemale from "../../Membership/MembershipFemale";
import VipUpgrade       from "../../Membership/VipUpgrade";
import TgVideo          from "../../TgVideo/TgVideo";
import PhotoUploadInfo  from "../../PhotoUploadInfo/PhotoUploadInfo";
import PhotoLimit       from "../../PhotoLimit/PhotoLimit";
import SendMessage      from '../../SendMessage/SendMessage';
import PhotoRequest     from '../../PhotoRequest/PhotoRequest';
import AddTrip          from "../../AddTrip/AddTrip";
import Location         from "../../Location/Location";
import Age              from "../../Age/Age";
import Verify           from "../../VerifyAccountPopup/Verify";
import VerifyRenew      from "../../VerifyRenew/VerifyRenew";
import VerifyBlocked    from "../../VerifyBlocked/VerifyBlocked";
import ContentAgreement from "../../ContentAgreement/ContentAgreement";
import NotificationMessage from "../../NotificationMessage/NotificationMessage";
import SendMessageUnregistered from '../../SendMessage/SendMessageUnregistered';
import FinishRegistration from "../../FinishRegistration/FinishRegistration";
const DeleteView = lazy(() => import("../../DeleteView/DeleteView"));
const EmailValidation = lazy(() => import("../../EmailValidation/EmailValidation"));
const StripePayForm = lazy(() => import("../../../containers/Pages/Membership/StripePayForm"));
const MembershipFemaleDiamondReminder = lazy(() => import("../../MembershipFemaleDiamondReminder"));
const VipDiscountedOffer = lazy(() => import("../../../containers/Pages/Membership/VipDiscountedOffer"));

const Modal = (props) => {
    const dispatch = useDispatch();
    const isOpened = useSelector(state => state.modal.isOpened);
    const disableCloseBtn = useSelector(state => state.modal.disabledClose);
    const windowOpened = useSelector(state => state.modal.modal);
    const payloadData = useSelector(state => state.modal.data);

    const closeModal = () => {
        if (!disableCloseBtn) {
            dispatch(modalActions.close());
        }
    }

    const closeModalForce = () => {
        dispatch(modalActions.close());
    }

    const openModal = (modelName) => {
        dispatch(modalActions.open({name: modelName}));
    }

    if (isOpened) {
        document.body.style.overflow = 'hidden';
    } else {
        document.body.style.overflow = 'unset';
    }

    const mainClassList = [classes.Modal];
    if (windowOpened.tgVideo) {
        mainClassList.push(classes.VideoModal);
    }

    if (!isOpened) {
        return null;
    }

    const customStyle = payloadData?.maxWidth ? {maxWidth: payloadData.maxWidth} : {};

    return (
        <Fragment>
            <div className={mainClassList.join(' ')}>
                <div className={classes.Backdrop} onClick={closeModal} />
                <div className={classes.ModalWrapper}>
                    <div className={classes.ModalWindow} style={customStyle}>
                        <div className={classes.ModalContainer}>
                            {!disableCloseBtn && <div className={classes.CloseButton} onClick={closeModal}><i className="tg-icon icon-close-big"></i></div>}
                            {windowOpened.login ? <Login openModal={openModal} modalClosed={closeModal}/> : null}
                            {windowOpened.registration ? <Registration openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.passwordReminder ? <ForgotPassword openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.preferences ? <Preferences openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.membership ? <Membership openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.membershipFemale ? <MembershipFemale openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.membershipFemaleDiamondReminder && <MembershipFemaleDiamondReminder openModal={openModal} modalClosed={closeModal} />}
                            {windowOpened.vipUpgrade ? <VipUpgrade openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.vipDiscounted && <VipDiscountedOffer openModal={openModal} modalClosed={closeModal} />}
                            {windowOpened.verify ? <Verify openModal={openModal} modalClosed={closeModal} data={payloadData} /> : null}
                            {windowOpened.verifyRenew ? <VerifyRenew openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.verifyBlocked ? <VerifyBlocked openModal={openModal} modalClosed={closeModal} modalClosedForce={closeModalForce} /> : null}
                            {windowOpened.photoUploadInfo ? <PhotoUploadInfo openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.photoLimit ? <PhotoLimit openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.tgVideo ? <TgVideo {...props} /> : null}
                            {windowOpened.sendMessage ? <SendMessage openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.sendMessageUnregistered ? <SendMessageUnregistered openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.finishRegistration ? <FinishRegistration openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.photoRequest ? <PhotoRequest openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.addTrip ? <AddTrip openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.location ? <Location openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.age ? <Age openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.contentAgreement ? <ContentAgreement openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.notificationMessage ? <NotificationMessage openModal={openModal} modalClosed={closeModal} /> : null}
                            {windowOpened.emailValidation && <EmailValidation />}
                            {windowOpened.paymentStripe && <StripePayForm data={payloadData} />}
                            {windowOpened.deleteView && <DeleteView data={payloadData} />}
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
}

export default Modal;
