import {useEffect, useState} from "react";
import {useSelector} from "react-redux";
import {initializeApp} from "firebase/app";
import {getMessaging, getToken} from "firebase/messaging";

import {isMobileDevice} from "../helpers/MobileDesignDetect";
import axios from "../config/Axios";
import {vapidKey, firebaseConfig} from "../config/Config";

export const isPushSupported = () => {
    const supportsSafariPush = () => (window.safari && typeof window.safari.pushNotification !== "undefined");
    const supportsVapidPush = () => (
        typeof PushSubscriptionOptions !== "undefined" &&
        PushSubscriptionOptions.prototype.hasOwnProperty("applicationServerKey")
    );

    return  isMobileDevice() && ("Notification" in window) && (supportsVapidPush() || supportsSafariPush());
}

let messaging = null;
let intervalId = null;
let swRegistration = false;
const useFcmTokenCheck = () => {
    const isEnabled = isPushSupported();
    const userId = useSelector(state => state.auth.user ? state.auth.user.id : null);
    const isFetched = useSelector(state => state.auth.fetchOnInit);
    const unreadCount = useSelector(state => state.counters?.unreadConversations);
    const permissionUpdate = useSelector(state => state.auth.notificationPermissionGranted);
    const permissionStatus = typeof Notification !== 'undefined' ? Notification.permission : 'default';
    const [fcmToken, setFcmToken] = useState(null);

    // Init worker and append listener
    useEffect(() => {
        if (swRegistration) return;
        swRegistration = true;

        const reloadPageOnClick = (e) => {
            if (e && e.data && e.data?.action === 'onNotificationClick' && e.data?.url) {
                window.location.href = e.data.url;
            }
        }

        if ('serviceWorker' in navigator) {
            navigator.serviceWorker.register('/firebase-messaging-sw.js')
                .then(() => navigator.serviceWorker.addEventListener('message', reloadPageOnClick))
                .catch(e => {
                    console.error('Service Worker - ERROR', e)
                });
        }

        return () => {
            if ('serviceWorker' in navigator) {
                navigator.serviceWorker.removeEventListener('message', reloadPageOnClick);
            }
        }
    }, []);

    // Check token
    useEffect(() => {
        if (!isFetched || !isEnabled || permissionStatus !== 'granted') return;

        if (!messaging) {
            try {
                messaging = getMessaging(initializeApp(firebaseConfig));
            } catch (e) {}
        }

        getToken(messaging, {vapidKey: vapidKey}).then(t => {
            if (!t) return;
            setFcmToken(t);
        }).catch((err) => {console.error('An error occurred while retrieving token. ', err)});
    }, [userId, isFetched, isEnabled, permissionStatus, permissionUpdate]);

    // Inform backend about token and user on every change, and set interval just send it to back every 90seconds.
    useEffect(() => {
        if (intervalId) clearInterval(intervalId);
        if (!fcmToken) return;
        const updateBack = () => axios.post(`tokens/fcm`, {token: fcmToken, userId: userId});

        intervalId = setInterval(updateBack, 120000);
        updateBack();

        return () => {
            if (intervalId) clearInterval(intervalId);
        }
    }, [userId, fcmToken]);

    // Update badge counter
    useEffect(() => {
        // Check if the Badging API is supported
        if ('setAppBadge' in navigator && typeof navigator.setAppBadge === 'function') {
            try {
                navigator.setAppBadge(userId ? unreadCount : 0);
            } catch (error) {
                console.error('Error setting badge:', error);
            }
        }
        else {
            console.warn('Badging API not supported in this browser.');
        }
    }, [unreadCount, userId]);
}

export default useFcmTokenCheck;
