import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import Hotjar from "@hotjar/browser";

import useQuery from "./useQuery";
import {isTravelGirls} from "../config/Flavor";

const siteId = 4988682;
const hotjarVersion = 6;
const hotjarLS = 'HOTJAR_ENABLE';

const useHotjar = () => {
    const location = useLocation();
    const utmSource = useQuery().get('utm_source');
    const hotjarInternalEnabled = localStorage.getItem(hotjarLS);

    useEffect(() => {
        const checkAndEnable = () => {
            if (isTravelGirls) return;

            let isEnabled = false;
            if (hotjarInternalEnabled === null) {
                isEnabled = (utmSource === 'facebook' || location.pathname.startsWith('/dubai'))
                localStorage.setItem(hotjarLS, isEnabled ? '1' : '0');
            }
            else {
                isEnabled = hotjarInternalEnabled === '1';
            }

            if (isEnabled) {
                console.log('Init Hotjar');
                Hotjar.init(siteId, hotjarVersion);
            }
        }

        checkAndEnable()
    }, [isTravelGirls]);
}

export default useHotjar;
