import {lazy} from 'react';

import Landing from "../containers/Pages/Landing/Landing";
import Yachts from "../containers/Pages/Yachts/Page/Yachts";
import Dubai from "../containers/Pages/Dubai/Dubai";
import LandingGay from "../containers/Pages/LandingGay/LandingGay";

// Lazy load
const Members = lazy(() => import('../containers/Pages/Members/Members'));
const Member = lazy(() => import('../containers/Pages/Member/Member'));
const NotFound = lazy(() => import('../containers/Pages/NotFound/NotFound'));
const Support = lazy(() => import('../containers/Pages/Support/Support'));
const PasswordReset = lazy(() => import('../containers/Pages/PasswordReset/PasswordReset'));
const RegistrationValidation = lazy(() => import("../containers/Pages/RegistrationValidation/RegistrationValidation"));
const Suspended = lazy(() => import("../containers/Pages/Suspended/Suspended"));
const Verification = lazy(() => import("../containers/Pages/Verification/Verification"));
const Conversations = lazy(() => import('../containers/Pages/Conversations/Conversations'));
const Favorites = lazy(() => import("../containers/Pages/Favorites/Favorites"));
const Visitors = lazy(() => import("../containers/Pages/Visitors/Visitors"));
const Trips = lazy(() => import("../containers/Pages/Trips/Trips"));
const MyTrips = lazy(() => import("../containers/Pages/Trips/MyTrips"));
const Edit = lazy(() => import("../containers/Pages/Edit/Edit"));
const MyProfileMobile = lazy(() => import("../containers/Pages/MyProfileMobile/MyProfileMobile"));
const Membership = lazy(() => import("../containers/Pages/Membership/Membership"));
const MembershipPlan = lazy(() => import("../containers/Pages/Membership/MembershipPlan"));
const MembershipCancel = lazy(() => import("../containers/Pages/Membership/Cancel/MembershipCancel"));
const PaymentsHistory = lazy(() => import("../containers/Pages/Membership/PaymentsHistory/PaymentsHistory"));
const PhotoRequests = lazy(() => import("../containers/Pages/PhotoRequests/PhotoRequests"));
const GroupMessage = lazy(() => import("../containers/Pages/GroupMessage/GroupMessage"))
const Logout = lazy(() => import("../containers/Pages/Logout/Logout"));
const Unsubscribe = lazy(() => import('../containers/Pages/Unsubscribe/Unsubscribe'));
const ReportUser = lazy (() => import('../containers/Pages/Member/ReportUser/ReportUser'));
const UnwantedUsers = lazy (() => import('../containers/Pages/UnwantedUsers/UnwantedUsers'));
const UnwantedCountries = lazy (() => import('../containers/Pages/UnwantedCountries/UnwantedCountries'));
const AboutTravelgirls = lazy(() => import("../containers/Pages/AboutTravelgirls/AboutTravelgirls"));
const HomeScreen = lazy(() => import("../containers/Pages/HomeScreen/HomeScreen"));
const FAQ = lazy(() => import("../containers/Pages/FAQ/FAQ"));
const TravelTips = lazy(() => import("../containers/Pages/TravelTips/TravelTips"));
const TermsAndConditions = lazy(() => import("../containers/Pages/TermsAndConditions/TermsAndConditions"));
const PrivacyPolicy = lazy(() => import("../containers/Pages/PrivacyPolicy/PrivacyPolicy"));
const TravelApp = lazy(() => import("../containers/Pages/TravelApp/TravelApp"));
const Escort = lazy(() => import("../containers/Pages/Escort/Escort"));
const EscortCountries = lazy(() => import("../containers/Pages/Escort/EscortCountries"));
const EscortCities = lazy(() => import("../containers/Pages/Escort/EscortCities"));
const SugarBaby = lazy(() => import("../containers/Pages/Escort/SugarBaby"));
const SugarBabyUsa = lazy(() => import("../containers/Pages/Escort/SugarBabyUsa"));
const CameraVerification = lazy(() => import("../containers/Pages/Verification/CameraVerification"))
const Complaint = lazy(() => import("../containers/Pages/Complaint/Complaint"));

export const routes = {
    notFound: {path: '*', view: NotFound, hideSidebar: true},
    travelgirlsApp: {path: '/travelgirls-app', view: TravelApp, hideSidebar: true, fullWidth: true},
    travelTips: {path: '/travel-tips', view: TravelTips},
    faq: {path: '/faq', view: FAQ},
    privacyPolicy: {path: '/privacy-policy', view: PrivacyPolicy},
    termsAndConditions: {path: '/terms-and-conditions', view: TermsAndConditions},
    aboutTravelgirls: {path: 'about-us', view: AboutTravelgirls},
    homeScreen: {path: 'home-screen', hideSidebar: true, view: HomeScreen, mobileMenuVisible: false},
    reportUser: {path: '/report-user', view: ReportUser, authRequired: true},
    reportComplaint: {path: '/report-complaint', view: Complaint, authRequired: false},
    unwantedCountries: {path: '/hide-profile', view: UnwantedCountries, authRequired: true},
    unwantedUsers: {path: '/unwanted-users', view: UnwantedUsers, authRequired: true},
    groupMessage: {path: '/group-message', view: GroupMessage, authRequired: true},
    verification: {path: '/verification', view: Verification, authRequired: true},
    verificationCamera: {path: '/camera-verification', view: CameraVerification, authRequired: false},
    suspended: {path: '/suspended', view: Suspended, authRequired: true, hideSidebar: true},
    membership: {path: '/membership', view: Membership, authRequired: true},
    membershipPayments: {path: '/membership/payments', view: PaymentsHistory, authRequired: true},
    membershipCancel: {path: '/membership/cancel', view: MembershipCancel, authRequired: true},
    membershipPlan: {path: '/membership/:plan', view: MembershipPlan, authRequired: true, hideSidebar: true},
    edit: {path: '/edit', view: Edit, authRequired: true},
    editTab: {path: '/edit/:tab', view: Edit, authRequired: true},
    myProfile: {path: '/profile', view: MyProfileMobile, authRequired: true},
    photoRequests: {path: '/photo-requests', view: PhotoRequests, authRequired: true},
    favorites: {path: '/favorites', view: Favorites, authRequired: true},
    visitors: {path: '/visitors', view: Visitors, authRequired: true},
    myTrips: {path: '/my-trips', view: MyTrips, authRequired: true},
    conversations: {path: '/conversations', view: Conversations, hideMainContentSpacer: true, hideFooter: true, authRequired: true},
    trips: {path: '/trips', view: Trips},
    escortCountries: {path: '/escort', view: EscortCountries, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    escortCities: {path: '/escort/:country', view: EscortCities, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    escort: {path: '/escort/:country/:city', view: Escort, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarDaddyCountries: {path: '/sugar-daddy-dating', view: EscortCountries, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarDaddyCities: {path: '/sugar-daddy-dating/:country', view: EscortCities, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarDaddy: {path: '/sugar-daddy-dating/:country/:city', view: Escort, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBaby: {path: '/sugar-baby-atlanta', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyNewYork: {path: '/sugar-baby-new-york', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyLosAngeles: {path: '/sugar-baby-los-angeles', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyChicago: {path: '/sugar-baby-chicago', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyAustin: {path: '/sugar-baby-austin', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyMiami: {path: '/sugar-baby-miami', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabySeattle: {path: '/sugar-baby-seattle', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyDenver: {path: '/sugar-baby-denver', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyNashville: {path: '/sugar-baby-nashville', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyPortland: {path: '/sugar-baby-portland', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyHouston: {path: '/sugar-baby-houston', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyBoston: {path: '/sugar-baby-boston', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyPhiladelphia: {path: '/sugar-baby-philadelphia', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyDallas: {path: '/sugar-baby-dallas', view: SugarBaby, hideSidebar: true, fullWidth: true, headerAlwaysVisible: true, mobileMenuVisible: false},
    sugarBabyUs: {path: '/sugar-baby-us', view: SugarBabyUsa, hideSidebar: true, fullWidth: true, headerAlwaysVisible: false, mobileMenuVisible: false},
    passwordReset: {path: '/password-reset', view: PasswordReset},
    login: {path: '/login', view: RegistrationValidation},
    logout: {path: '/logout', view: Logout, hideSidebar: true, fullWidth: true},
    support: {path: '/support', view: Support},
    members: {path: '/members', view: Members, newMembersInSidebar: true},
    member: {path: '/member/:userUrl', view: Member, hideSidebar: true},
    landing: {path: '/', view: Landing, hideSidebar: true, fullWidth: true, mobileMenuVisible: false},
    yachts: {path: '/yachts', view: Yachts},
    dubai: {path: '/dubai', view: Dubai},
    landingGay: {path: '/gay-traveling', view: LandingGay},
}
