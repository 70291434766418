import {useDispatch, useSelector} from "react-redux";

import classes from './Membership.module.scss';
import stopSign from './stop-sign.svg';
import aggPhoto, {getMainAggregated, getMainPhotoInMemberProfile} from "../../helpers/Photos";
import { getCurrencySign } from "../../helpers/Text";
import { modalActions } from "../../store/modal";

const MembershipFemale = () => {
    const dispatch = useDispatch();
    const user = useSelector(state => state.auth.user);
    const pricePoint = user.additional.prices.list['1month_1998'];
    const currency = user.additional.prices.currency;
    const profileWatched = useSelector(state => state.modal.data.user);

    const profileWatchedPhotoUrl = (profileWatched.photo)
        ? aggPhoto(profileWatched.photo, 100, profileWatched)
        : getMainPhotoInMemberProfile(profileWatched, 100);

    return (
        <div className={classes.Area}>
            <h2><b>Start a conversation!</b></h2>
            <div className={[classes.ImageArea, classes.SmallArea].join(' ')}>
                <img className={[classes.ImgUser, classes.ImgUserOutline].join(' ')} src={getMainAggregated(user, 100)} alt={''} />
                <div className={classes.LineFemale}>
                    <img src={stopSign} alt={''} />
                </div>
                <img className={[classes.ImgUser, classes.ImgUserOutline, classes.ImgUserToContact].join(' ')} src={profileWatchedPhotoUrl} alt={''}/>
            </div>
            <div className={classes.Text}><h3>Upgrade your membership<br /> to contact others</h3></div>
            <div className={classes.Text}>
                <span className={classes.NewPrice}>{getCurrencySign(currency)}19.98 for 1  month</span>
            </div>
            <button onClick={showStripePopup} className={["ButtonRounded", "ButtonRoundedBig", classes.Upgrade].join(' ')}>
                <b>Upgrade to Advanced</b>
            </button>
            <p className={classes.CancelText}>You can cancel at any time</p>
            <div className={classes.TextSmall}>
                This payment is an auto-recurring periodic subscription method. 
                You will be charged immediately for the initial period of the subscription. 
                Your IAP Account will  be billed continuously for the subscription until you cancel. 
                You can read more on Terms of Service and Privacy Policy pages
            </div>
        </div>
    )

    function showStripePopup() {
        const plan = pricePoint;
        const stripeData = {
            userId: user.id,
            id: plan.idStripe,
            price: plan.price,
            currency: currency,
            label: 'Monthly premium membership'
        };
        dispatch(modalActions.open({name: 'paymentStripe', data: stripeData}));
    }
};

export default MembershipFemale;
