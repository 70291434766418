import classes from './VerifyRenew.module.scss';
import verifyIcon from './assets/verifyIcon.png';
import {useNavigate} from "react-router-dom";

const VerifyRenew = (props) => {
    const nav = useNavigate();

    const gotoVerify = () => {
        props.modalClosed();
        nav('/verification');
    }

    const gotoVerifyUpload = () => {
        props.modalClosed();
        nav('/verification/step1');
    }

    return (
        <div className={classes.Area}>
            <img src={verifyIcon} alt="verification icon"/>
            <h3>Your profile verification is about to expire.</h3>
            <button onClick={gotoVerifyUpload} className={["ButtonRounded", "ButtonRoundedBig"].join(' ')}>Update verifcation now</button>
            <p onClick={gotoVerify}>Why do I need to get verified?</p>
        </div>
    );
}

export default VerifyRenew;
